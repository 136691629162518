import AboutMeSection from "./AboutMeSection"

function HomePageBody() {
    return (
        <div className="homePageContentContainer ">
            <div className="homePageContent">
                <AboutMeSection />
                {/* <div className="flex h-[1/5] w-3/4 bg-gray-400 rounded-[1vw]"> rRre</div> */}

            </div>

        </div>
    )
}

export default HomePageBody
