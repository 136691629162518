import Resume from "../../../Assets/Resume.jpg"


function ResumePageBody() {
    return (
        <div className="">
            <img src={Resume} className="resume" alt='' ></img>
        </div>
    )
}

export default ResumePageBody
